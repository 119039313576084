<template>
  <a-drawer
      title="详情"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="closed"
  >
    <drawer-layout title="分类详情">
      <div class="flex" slot="actions" v-if="isEdit">
        <a-button icon="save" type="primary" @click="save">保存</a-button>
        <a-button class="ml-3" @click="isEdit = false">取消</a-button>
      </div>
      <div class="flex" slot="actions" v-else>
        <a-button icon="edit" type="primary" @click="isEdit = true">编辑</a-button>
      </div>
      
      <div class="info-group">
        <info-item label="序号" label-right>
          <a-input v-model="sort" v-if="isEdit" class="w-48"></a-input>
          <span v-else>{{info.sequenceNo}}</span>
        </info-item>
        <info-item label="分类名称" label-right>
          <a-input v-model="cname" v-if="isEdit" class="w-48"></a-input>
          <span v-else>{{info.templateClassName}}</span>
        </info-item>
      </div>
    </drawer-layout>
  </a-drawer>
</template>

<script>
import { getAction, putAction } from "@/api/manage";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: [Number,String],
    row: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return{
      isEdit: false,
      cname: '',
      sort: '',
      info: {},
    }
  },
  watch: {
    show(val) {
      this.getDetail()
    },
    
  },
  methods:{
    closed() {
      this.$emit('closed',false)
    },
    getDetail() {
      getAction('/terms/get_template_class',{id: this.id})
        .then(res => {
          if (res.success) {
            this.info = res.data
            this.cname = this.info.templateClassName
            this.sort = this.info.sequenceNo
          }
        })
    },
    save() {
      const params = {
        templateClassId: this.id,
	      templateClassName: this.cname,
	      sequenceNo: this.sort
      }
      this.isEdit = false
      putAction('/terms/edit_template_class',params).then(res => {
        if (res.success) {
          this.isEdit = false
          this.$message.success(res.message)
          this.closed()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    del() {
      console.log('删除');
    }
  },
  
  created(){
    
  }
}
</script>

<style lang='stylus'>

</style>